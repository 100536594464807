/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { create as t } from "../factories/vec3f64.js";
import { getEpsilon as a } from "./common.js";
function n(t, a) {
  return t[0] = a[0], t[1] = a[1], t[2] = a[2], t[3] = a[3], t[4] = a[4], t[5] = a[5], t[6] = a[6], t[7] = a[7], t[8] = a[8], t[9] = a[9], t[10] = a[10], t[11] = a[11], t[12] = a[12], t[13] = a[13], t[14] = a[14], t[15] = a[15], t;
}
function r(t, a, n, r, o, s, e, h, u, M, c, i, f, b, l, m, x) {
  return t[0] = a, t[1] = n, t[2] = r, t[3] = o, t[4] = s, t[5] = e, t[6] = h, t[7] = u, t[8] = M, t[9] = c, t[10] = i, t[11] = f, t[12] = b, t[13] = l, t[14] = m, t[15] = x, t;
}
function o(t) {
  return t[0] = 1, t[1] = 0, t[2] = 0, t[3] = 0, t[4] = 0, t[5] = 1, t[6] = 0, t[7] = 0, t[8] = 0, t[9] = 0, t[10] = 1, t[11] = 0, t[12] = 0, t[13] = 0, t[14] = 0, t[15] = 1, t;
}
function s(t, a) {
  if (t === a) {
    const n = a[1],
      r = a[2],
      o = a[3],
      s = a[6],
      e = a[7],
      h = a[11];
    t[1] = a[4], t[2] = a[8], t[3] = a[12], t[4] = n, t[6] = a[9], t[7] = a[13], t[8] = r, t[9] = s, t[11] = a[14], t[12] = o, t[13] = e, t[14] = h;
  } else t[0] = a[0], t[1] = a[4], t[2] = a[8], t[3] = a[12], t[4] = a[1], t[5] = a[5], t[6] = a[9], t[7] = a[13], t[8] = a[2], t[9] = a[6], t[10] = a[10], t[11] = a[14], t[12] = a[3], t[13] = a[7], t[14] = a[11], t[15] = a[15];
  return t;
}
function e(t, a) {
  return h(t, a) || o(t), t;
}
function h(t, a) {
  const n = a[0],
    r = a[1],
    o = a[2],
    s = a[3],
    e = a[4],
    h = a[5],
    u = a[6],
    M = a[7],
    c = a[8],
    i = a[9],
    f = a[10],
    b = a[11],
    l = a[12],
    m = a[13],
    x = a[14],
    q = a[15],
    d = n * h - r * e,
    p = n * u - o * e,
    g = n * M - s * e,
    y = r * u - o * h,
    R = r * M - s * h,
    v = o * M - s * u,
    S = c * m - i * l,
    T = c * x - f * l,
    I = c * q - b * l,
    O = i * x - f * m,
    j = i * q - b * m,
    P = f * q - b * x;
  let D = d * P - p * j + g * O + y * I - R * T + v * S;
  return D ? (D = 1 / D, t[0] = (h * P - u * j + M * O) * D, t[1] = (o * j - r * P - s * O) * D, t[2] = (m * v - x * R + q * y) * D, t[3] = (f * R - i * v - b * y) * D, t[4] = (u * I - e * P - M * T) * D, t[5] = (n * P - o * I + s * T) * D, t[6] = (x * g - l * v - q * p) * D, t[7] = (c * v - f * g + b * p) * D, t[8] = (e * j - h * I + M * S) * D, t[9] = (r * I - n * j - s * S) * D, t[10] = (l * R - m * g + q * d) * D, t[11] = (i * g - c * R - b * d) * D, t[12] = (h * T - e * O - u * S) * D, t[13] = (n * O - r * T + o * S) * D, t[14] = (m * p - l * y - x * d) * D, t[15] = (c * y - i * p + f * d) * D, t) : null;
}
function u(t, a) {
  const n = a[0],
    r = a[1],
    o = a[2],
    s = a[3],
    e = a[4],
    h = a[5],
    u = a[6],
    M = a[7],
    c = a[8],
    i = a[9],
    f = a[10],
    b = a[11],
    l = a[12],
    m = a[13],
    x = a[14],
    q = a[15];
  return t[0] = h * (f * q - b * x) - i * (u * q - M * x) + m * (u * b - M * f), t[1] = -(r * (f * q - b * x) - i * (o * q - s * x) + m * (o * b - s * f)), t[2] = r * (u * q - M * x) - h * (o * q - s * x) + m * (o * M - s * u), t[3] = -(r * (u * b - M * f) - h * (o * b - s * f) + i * (o * M - s * u)), t[4] = -(e * (f * q - b * x) - c * (u * q - M * x) + l * (u * b - M * f)), t[5] = n * (f * q - b * x) - c * (o * q - s * x) + l * (o * b - s * f), t[6] = -(n * (u * q - M * x) - e * (o * q - s * x) + l * (o * M - s * u)), t[7] = n * (u * b - M * f) - e * (o * b - s * f) + c * (o * M - s * u), t[8] = e * (i * q - b * m) - c * (h * q - M * m) + l * (h * b - M * i), t[9] = -(n * (i * q - b * m) - c * (r * q - s * m) + l * (r * b - s * i)), t[10] = n * (h * q - M * m) - e * (r * q - s * m) + l * (r * M - s * h), t[11] = -(n * (h * b - M * i) - e * (r * b - s * i) + c * (r * M - s * h)), t[12] = -(e * (i * x - f * m) - c * (h * x - u * m) + l * (h * f - u * i)), t[13] = n * (i * x - f * m) - c * (r * x - o * m) + l * (r * f - o * i), t[14] = -(n * (h * x - u * m) - e * (r * x - o * m) + l * (r * u - o * h)), t[15] = n * (h * f - u * i) - e * (r * f - o * i) + c * (r * u - o * h), t;
}
function M(t) {
  const a = t[0],
    n = t[1],
    r = t[2],
    o = t[3],
    s = t[4],
    e = t[5],
    h = t[6],
    u = t[7],
    M = t[8],
    c = t[9],
    i = t[10],
    f = t[11],
    b = t[12],
    l = t[13],
    m = t[14],
    x = t[15];
  return (a * e - n * s) * (i * x - f * m) - (a * h - r * s) * (c * x - f * l) + (a * u - o * s) * (c * m - i * l) + (n * h - r * e) * (M * x - f * b) - (n * u - o * e) * (M * m - i * b) + (r * u - o * h) * (M * l - c * b);
}
function c(t, a, n) {
  const r = a[0],
    o = a[1],
    s = a[2],
    e = a[3],
    h = a[4],
    u = a[5],
    M = a[6],
    c = a[7],
    i = a[8],
    f = a[9],
    b = a[10],
    l = a[11],
    m = a[12],
    x = a[13],
    q = a[14],
    d = a[15];
  let p = n[0],
    g = n[1],
    y = n[2],
    R = n[3];
  return t[0] = p * r + g * h + y * i + R * m, t[1] = p * o + g * u + y * f + R * x, t[2] = p * s + g * M + y * b + R * q, t[3] = p * e + g * c + y * l + R * d, p = n[4], g = n[5], y = n[6], R = n[7], t[4] = p * r + g * h + y * i + R * m, t[5] = p * o + g * u + y * f + R * x, t[6] = p * s + g * M + y * b + R * q, t[7] = p * e + g * c + y * l + R * d, p = n[8], g = n[9], y = n[10], R = n[11], t[8] = p * r + g * h + y * i + R * m, t[9] = p * o + g * u + y * f + R * x, t[10] = p * s + g * M + y * b + R * q, t[11] = p * e + g * c + y * l + R * d, p = n[12], g = n[13], y = n[14], R = n[15], t[12] = p * r + g * h + y * i + R * m, t[13] = p * o + g * u + y * f + R * x, t[14] = p * s + g * M + y * b + R * q, t[15] = p * e + g * c + y * l + R * d, t;
}
function i(t, a, n) {
  const r = n[0],
    o = n[1],
    s = n[2];
  if (a === t) t[12] = a[0] * r + a[4] * o + a[8] * s + a[12], t[13] = a[1] * r + a[5] * o + a[9] * s + a[13], t[14] = a[2] * r + a[6] * o + a[10] * s + a[14], t[15] = a[3] * r + a[7] * o + a[11] * s + a[15];else {
    const n = a[0],
      e = a[1],
      h = a[2],
      u = a[3],
      M = a[4],
      c = a[5],
      i = a[6],
      f = a[7],
      b = a[8],
      l = a[9],
      m = a[10],
      x = a[11];
    t[0] = n, t[1] = e, t[2] = h, t[3] = u, t[4] = M, t[5] = c, t[6] = i, t[7] = f, t[8] = b, t[9] = l, t[10] = m, t[11] = x, t[12] = n * r + M * o + b * s + a[12], t[13] = e * r + c * o + l * s + a[13], t[14] = h * r + i * o + m * s + a[14], t[15] = u * r + f * o + x * s + a[15];
  }
  return t;
}
function f(t, a, n) {
  const r = n[0],
    o = n[1],
    s = n[2];
  return t[0] = a[0] * r, t[1] = a[1] * r, t[2] = a[2] * r, t[3] = a[3] * r, t[4] = a[4] * o, t[5] = a[5] * o, t[6] = a[6] * o, t[7] = a[7] * o, t[8] = a[8] * s, t[9] = a[9] * s, t[10] = a[10] * s, t[11] = a[11] * s, t[12] = a[12], t[13] = a[13], t[14] = a[14], t[15] = a[15], t;
}
function b(t, r, o, s) {
  let e = s[0],
    h = s[1],
    u = s[2],
    M = Math.sqrt(e * e + h * h + u * u);
  if (M <= a()) return void n(t, r);
  M = 1 / M, e *= M, h *= M, u *= M;
  const c = Math.sin(o),
    i = Math.cos(o),
    f = 1 - i,
    b = r[0],
    l = r[1],
    m = r[2],
    x = r[3],
    q = r[4],
    d = r[5],
    p = r[6],
    g = r[7],
    y = r[8],
    R = r[9],
    v = r[10],
    S = r[11],
    T = e * e * f + i,
    I = h * e * f + u * c,
    O = u * e * f - h * c,
    j = e * h * f - u * c,
    P = h * h * f + i,
    D = u * h * f + e * c,
    _ = e * u * f + h * c,
    A = h * u * f - e * c,
    w = u * u * f + i;
  t[0] = b * T + q * I + y * O, t[1] = l * T + d * I + R * O, t[2] = m * T + p * I + v * O, t[3] = x * T + g * I + S * O, t[4] = b * j + q * P + y * D, t[5] = l * j + d * P + R * D, t[6] = m * j + p * P + v * D, t[7] = x * j + g * P + S * D, t[8] = b * _ + q * A + y * w, t[9] = l * _ + d * A + R * w, t[10] = m * _ + p * A + v * w, t[11] = x * _ + g * A + S * w, r !== t && (t[12] = r[12], t[13] = r[13], t[14] = r[14], t[15] = r[15]);
}
function l(t, a, n) {
  const r = Math.sin(n),
    o = Math.cos(n),
    s = a[4],
    e = a[5],
    h = a[6],
    u = a[7],
    M = a[8],
    c = a[9],
    i = a[10],
    f = a[11];
  return a !== t && (t[0] = a[0], t[1] = a[1], t[2] = a[2], t[3] = a[3], t[12] = a[12], t[13] = a[13], t[14] = a[14], t[15] = a[15]), t[4] = s * o + M * r, t[5] = e * o + c * r, t[6] = h * o + i * r, t[7] = u * o + f * r, t[8] = M * o - s * r, t[9] = c * o - e * r, t[10] = i * o - h * r, t[11] = f * o - u * r, t;
}
function m(t, a, n) {
  const r = Math.sin(n),
    o = Math.cos(n),
    s = a[0],
    e = a[1],
    h = a[2],
    u = a[3],
    M = a[8],
    c = a[9],
    i = a[10],
    f = a[11];
  return a !== t && (t[4] = a[4], t[5] = a[5], t[6] = a[6], t[7] = a[7], t[12] = a[12], t[13] = a[13], t[14] = a[14], t[15] = a[15]), t[0] = s * o - M * r, t[1] = e * o - c * r, t[2] = h * o - i * r, t[3] = u * o - f * r, t[8] = s * r + M * o, t[9] = e * r + c * o, t[10] = h * r + i * o, t[11] = u * r + f * o, t;
}
function x(t, a, n) {
  const r = Math.sin(n),
    o = Math.cos(n),
    s = a[0],
    e = a[1],
    h = a[2],
    u = a[3],
    M = a[4],
    c = a[5],
    i = a[6],
    f = a[7];
  return a !== t && (t[8] = a[8], t[9] = a[9], t[10] = a[10], t[11] = a[11], t[12] = a[12], t[13] = a[13], t[14] = a[14], t[15] = a[15]), t[0] = s * o + M * r, t[1] = e * o + c * r, t[2] = h * o + i * r, t[3] = u * o + f * r, t[4] = M * o - s * r, t[5] = c * o - e * r, t[6] = i * o - h * r, t[7] = f * o - u * r, t;
}
function q(t, a) {
  return t[0] = 1, t[1] = 0, t[2] = 0, t[3] = 0, t[4] = 0, t[5] = 1, t[6] = 0, t[7] = 0, t[8] = 0, t[9] = 0, t[10] = 1, t[11] = 0, t[12] = a[0], t[13] = a[1], t[14] = a[2], t[15] = 1, t;
}
function d(t, a) {
  return t[0] = a[0], t[1] = 0, t[2] = 0, t[3] = 0, t[4] = 0, t[5] = a[1], t[6] = 0, t[7] = 0, t[8] = 0, t[9] = 0, t[10] = a[2], t[11] = 0, t[12] = 0, t[13] = 0, t[14] = 0, t[15] = 1, t;
}
function p(t, n, r) {
  if (0 === n) return o(t);
  let s = r[0],
    e = r[1],
    h = r[2],
    u = Math.sqrt(s * s + e * e + h * h);
  if (u <= a()) return null;
  u = 1 / u, s *= u, e *= u, h *= u;
  const M = Math.sin(n),
    c = Math.cos(n),
    i = 1 - c;
  return t[0] = s * s * i + c, t[1] = e * s * i + h * M, t[2] = h * s * i - e * M, t[3] = 0, t[4] = s * e * i - h * M, t[5] = e * e * i + c, t[6] = h * e * i + s * M, t[7] = 0, t[8] = s * h * i + e * M, t[9] = e * h * i - s * M, t[10] = h * h * i + c, t[11] = 0, t[12] = 0, t[13] = 0, t[14] = 0, t[15] = 1, t;
}
function g(t, a) {
  const n = Math.sin(a),
    r = Math.cos(a);
  return t[0] = 1, t[1] = 0, t[2] = 0, t[3] = 0, t[4] = 0, t[5] = r, t[6] = n, t[7] = 0, t[8] = 0, t[9] = -n, t[10] = r, t[11] = 0, t[12] = 0, t[13] = 0, t[14] = 0, t[15] = 1, t;
}
function y(t, a) {
  const n = Math.sin(a),
    r = Math.cos(a);
  return t[0] = r, t[1] = 0, t[2] = -n, t[3] = 0, t[4] = 0, t[5] = 1, t[6] = 0, t[7] = 0, t[8] = n, t[9] = 0, t[10] = r, t[11] = 0, t[12] = 0, t[13] = 0, t[14] = 0, t[15] = 1, t;
}
function R(t, a) {
  const n = Math.sin(a),
    r = Math.cos(a);
  return t[0] = r, t[1] = n, t[2] = 0, t[3] = 0, t[4] = -n, t[5] = r, t[6] = 0, t[7] = 0, t[8] = 0, t[9] = 0, t[10] = 1, t[11] = 0, t[12] = 0, t[13] = 0, t[14] = 0, t[15] = 1, t;
}
function v(t, a, n) {
  const r = a[0],
    o = a[1],
    s = a[2],
    e = a[3],
    h = r + r,
    u = o + o,
    M = s + s,
    c = r * h,
    i = r * u,
    f = r * M,
    b = o * u,
    l = o * M,
    m = s * M,
    x = e * h,
    q = e * u,
    d = e * M;
  return t[0] = 1 - (b + m), t[1] = i + d, t[2] = f - q, t[3] = 0, t[4] = i - d, t[5] = 1 - (c + m), t[6] = l + x, t[7] = 0, t[8] = f + q, t[9] = l - x, t[10] = 1 - (c + b), t[11] = 0, t[12] = n[0], t[13] = n[1], t[14] = n[2], t[15] = 1, t;
}
function S(t, a) {
  const n = T,
    r = -a[0],
    o = -a[1],
    s = -a[2],
    e = a[3],
    h = a[4],
    u = a[5],
    M = a[6],
    c = a[7],
    i = r * r + o * o + s * s + e * e;
  return i > 0 ? (n[0] = 2 * (h * e + c * r + u * s - M * o) / i, n[1] = 2 * (u * e + c * o + M * r - h * s) / i, n[2] = 2 * (M * e + c * s + h * o - u * r) / i) : (n[0] = 2 * (h * e + c * r + u * s - M * o), n[1] = 2 * (u * e + c * o + M * r - h * s), n[2] = 2 * (M * e + c * s + h * o - u * r)), v(t, a, n), t;
}
const T = t();
function I(t, a) {
  return t[0] = a[12], t[1] = a[13], t[2] = a[14], t;
}
function O(t, a) {
  const n = a[0],
    r = a[1],
    o = a[2],
    s = a[4],
    e = a[5],
    h = a[6],
    u = a[8],
    M = a[9],
    c = a[10];
  return t[0] = Math.sqrt(n * n + r * r + o * o), t[1] = Math.sqrt(s * s + e * e + h * h), t[2] = Math.sqrt(u * u + M * M + c * c), t;
}
function j(t, a) {
  const n = a[0] + a[5] + a[10];
  let r = 0;
  return n > 0 ? (r = 2 * Math.sqrt(n + 1), t[3] = .25 * r, t[0] = (a[6] - a[9]) / r, t[1] = (a[8] - a[2]) / r, t[2] = (a[1] - a[4]) / r) : a[0] > a[5] && a[0] > a[10] ? (r = 2 * Math.sqrt(1 + a[0] - a[5] - a[10]), t[3] = (a[6] - a[9]) / r, t[0] = .25 * r, t[1] = (a[1] + a[4]) / r, t[2] = (a[8] + a[2]) / r) : a[5] > a[10] ? (r = 2 * Math.sqrt(1 + a[5] - a[0] - a[10]), t[3] = (a[8] - a[2]) / r, t[0] = (a[1] + a[4]) / r, t[1] = .25 * r, t[2] = (a[6] + a[9]) / r) : (r = 2 * Math.sqrt(1 + a[10] - a[0] - a[5]), t[3] = (a[1] - a[4]) / r, t[0] = (a[8] + a[2]) / r, t[1] = (a[6] + a[9]) / r, t[2] = .25 * r), t;
}
function P(t, a, n, r) {
  const o = a[0],
    s = a[1],
    e = a[2],
    h = a[3],
    u = o + o,
    M = s + s,
    c = e + e,
    i = o * u,
    f = o * M,
    b = o * c,
    l = s * M,
    m = s * c,
    x = e * c,
    q = h * u,
    d = h * M,
    p = h * c,
    g = r[0],
    y = r[1],
    R = r[2];
  return t[0] = (1 - (l + x)) * g, t[1] = (f + p) * g, t[2] = (b - d) * g, t[3] = 0, t[4] = (f - p) * y, t[5] = (1 - (i + x)) * y, t[6] = (m + q) * y, t[7] = 0, t[8] = (b + d) * R, t[9] = (m - q) * R, t[10] = (1 - (i + l)) * R, t[11] = 0, t[12] = n[0], t[13] = n[1], t[14] = n[2], t[15] = 1, t;
}
function D(t, a, n, r, o) {
  const s = a[0],
    e = a[1],
    h = a[2],
    u = a[3],
    M = s + s,
    c = e + e,
    i = h + h,
    f = s * M,
    b = s * c,
    l = s * i,
    m = e * c,
    x = e * i,
    q = h * i,
    d = u * M,
    p = u * c,
    g = u * i,
    y = r[0],
    R = r[1],
    v = r[2],
    S = o[0],
    T = o[1],
    I = o[2],
    O = (1 - (m + q)) * y,
    j = (b + g) * y,
    P = (l - p) * y,
    D = (b - g) * R,
    _ = (1 - (f + q)) * R,
    A = (x + d) * R,
    w = (l + p) * v,
    F = (x - d) * v,
    Q = (1 - (f + m)) * v;
  return t[0] = O, t[1] = j, t[2] = P, t[3] = 0, t[4] = D, t[5] = _, t[6] = A, t[7] = 0, t[8] = w, t[9] = F, t[10] = Q, t[11] = 0, t[12] = n[0] + S - (O * S + D * T + w * I), t[13] = n[1] + T - (j * S + _ * T + F * I), t[14] = n[2] + I - (P * S + A * T + Q * I), t[15] = 1, t;
}
function _(t, a) {
  const n = a[0],
    r = a[1],
    o = a[2],
    s = a[3],
    e = n + n,
    h = r + r,
    u = o + o,
    M = n * e,
    c = r * e,
    i = r * h,
    f = o * e,
    b = o * h,
    l = o * u,
    m = s * e,
    x = s * h,
    q = s * u;
  return t[0] = 1 - i - l, t[1] = c + q, t[2] = f - x, t[3] = 0, t[4] = c - q, t[5] = 1 - M - l, t[6] = b + m, t[7] = 0, t[8] = f + x, t[9] = b - m, t[10] = 1 - M - i, t[11] = 0, t[12] = 0, t[13] = 0, t[14] = 0, t[15] = 1, t;
}
function A(t, a, n, r, o, s, e) {
  const h = 1 / (n - a),
    u = 1 / (o - r),
    M = 1 / (s - e);
  return t[0] = 2 * s * h, t[1] = 0, t[2] = 0, t[3] = 0, t[4] = 0, t[5] = 2 * s * u, t[6] = 0, t[7] = 0, t[8] = (n + a) * h, t[9] = (o + r) * u, t[10] = (e + s) * M, t[11] = -1, t[12] = 0, t[13] = 0, t[14] = e * s * 2 * M, t[15] = 0, t;
}
function w(t, a, n, r, o) {
  const s = 1 / Math.tan(a / 2);
  let e;
  return t[0] = s / n, t[1] = 0, t[2] = 0, t[3] = 0, t[4] = 0, t[5] = s, t[6] = 0, t[7] = 0, t[8] = 0, t[9] = 0, t[11] = -1, t[12] = 0, t[13] = 0, t[15] = 0, null != o && o !== 1 / 0 ? (e = 1 / (r - o), t[10] = (o + r) * e, t[14] = 2 * o * r * e) : (t[10] = -1, t[14] = -2 * r), t;
}
function F(t, a, n, r) {
  const o = Math.tan(a.upDegrees * Math.PI / 180),
    s = Math.tan(a.downDegrees * Math.PI / 180),
    e = Math.tan(a.leftDegrees * Math.PI / 180),
    h = Math.tan(a.rightDegrees * Math.PI / 180),
    u = 2 / (e + h),
    M = 2 / (o + s);
  return t[0] = u, t[1] = 0, t[2] = 0, t[3] = 0, t[4] = 0, t[5] = M, t[6] = 0, t[7] = 0, t[8] = -(e - h) * u * .5, t[9] = (o - s) * M * .5, t[10] = r / (n - r), t[11] = -1, t[12] = 0, t[13] = 0, t[14] = r * n / (n - r), t[15] = 0, t;
}
function Q(t, a, n, r, o, s, e) {
  const h = 1 / (a - n),
    u = 1 / (r - o),
    M = 1 / (s - e);
  return t[0] = -2 * h, t[1] = 0, t[2] = 0, t[3] = 0, t[4] = 0, t[5] = -2 * u, t[6] = 0, t[7] = 0, t[8] = 0, t[9] = 0, t[10] = 2 * M, t[11] = 0, t[12] = (a + n) * h, t[13] = (o + r) * u, t[14] = (e + s) * M, t[15] = 1, t;
}
function X(t, n, r, s) {
  const e = n[0],
    h = n[1],
    u = n[2];
  let M = e - r[0],
    c = h - r[1],
    i = u - r[2];
  const f = a();
  if (Math.abs(M) < f && Math.abs(c) < f && Math.abs(i) < f) return void o(t);
  let b = 1 / Math.sqrt(M * M + c * c + i * i);
  M *= b, c *= b, i *= b;
  const l = s[0],
    m = s[1],
    x = s[2];
  let q = m * i - x * c,
    d = x * M - l * i,
    p = l * c - m * M;
  b = Math.sqrt(q * q + d * d + p * p), b ? (b = 1 / b, q *= b, d *= b, p *= b) : (q = 0, d = 0, p = 0);
  let g = c * p - i * d,
    y = i * q - M * p,
    R = M * d - c * q;
  b = Math.sqrt(g * g + y * y + R * R), b ? (b = 1 / b, g *= b, y *= b, R *= b) : (g = 0, y = 0, R = 0), t[0] = q, t[1] = g, t[2] = M, t[3] = 0, t[4] = d, t[5] = y, t[6] = c, t[7] = 0, t[8] = p, t[9] = R, t[10] = i, t[11] = 0, t[12] = -(q * e + d * h + p * u), t[13] = -(g * e + y * h + R * u), t[14] = -(M * e + c * h + i * u), t[15] = 1;
}
function Y(t, a, n, r) {
  const o = a[0],
    s = a[1],
    e = a[2],
    h = r[0],
    u = r[1],
    M = r[2];
  let c = o - n[0],
    i = s - n[1],
    f = e - n[2],
    b = c * c + i * i + f * f;
  b > 0 && (b = 1 / Math.sqrt(b), c *= b, i *= b, f *= b);
  let l = u * f - M * i,
    m = M * c - h * f,
    x = h * i - u * c;
  return b = l * l + m * m + x * x, b > 0 && (b = 1 / Math.sqrt(b), l *= b, m *= b, x *= b), t[0] = l, t[1] = m, t[2] = x, t[3] = 0, t[4] = i * x - f * m, t[5] = f * l - c * x, t[6] = c * m - i * l, t[7] = 0, t[8] = c, t[9] = i, t[10] = f, t[11] = 0, t[12] = o, t[13] = s, t[14] = e, t[15] = 1, t;
}
function Z(t) {
  return "mat4(" + t[0] + ", " + t[1] + ", " + t[2] + ", " + t[3] + ", " + t[4] + ", " + t[5] + ", " + t[6] + ", " + t[7] + ", " + t[8] + ", " + t[9] + ", " + t[10] + ", " + t[11] + ", " + t[12] + ", " + t[13] + ", " + t[14] + ", " + t[15] + ")";
}
function k(t) {
  return Math.sqrt(t[0] ** 2 + t[1] ** 2 + t[2] ** 2 + t[3] ** 2 + t[4] ** 2 + t[5] ** 2 + t[6] ** 2 + t[7] ** 2 + t[8] ** 2 + t[9] ** 2 + t[10] ** 2 + t[11] ** 2 + t[12] ** 2 + t[13] ** 2 + t[14] ** 2 + t[15] ** 2);
}
function z(t, a, n) {
  return t[0] = a[0] + n[0], t[1] = a[1] + n[1], t[2] = a[2] + n[2], t[3] = a[3] + n[3], t[4] = a[4] + n[4], t[5] = a[5] + n[5], t[6] = a[6] + n[6], t[7] = a[7] + n[7], t[8] = a[8] + n[8], t[9] = a[9] + n[9], t[10] = a[10] + n[10], t[11] = a[11] + n[11], t[12] = a[12] + n[12], t[13] = a[13] + n[13], t[14] = a[14] + n[14], t[15] = a[15] + n[15], t;
}
function E(t, a, n) {
  return t[0] = a[0] - n[0], t[1] = a[1] - n[1], t[2] = a[2] - n[2], t[3] = a[3] - n[3], t[4] = a[4] - n[4], t[5] = a[5] - n[5], t[6] = a[6] - n[6], t[7] = a[7] - n[7], t[8] = a[8] - n[8], t[9] = a[9] - n[9], t[10] = a[10] - n[10], t[11] = a[11] - n[11], t[12] = a[12] - n[12], t[13] = a[13] - n[13], t[14] = a[14] - n[14], t[15] = a[15] - n[15], t;
}
function N(t, a, n) {
  return t[0] = a[0] * n, t[1] = a[1] * n, t[2] = a[2] * n, t[3] = a[3] * n, t[4] = a[4] * n, t[5] = a[5] * n, t[6] = a[6] * n, t[7] = a[7] * n, t[8] = a[8] * n, t[9] = a[9] * n, t[10] = a[10] * n, t[11] = a[11] * n, t[12] = a[12] * n, t[13] = a[13] * n, t[14] = a[14] * n, t[15] = a[15] * n, t;
}
function V(t, a, n, r) {
  return t[0] = a[0] + n[0] * r, t[1] = a[1] + n[1] * r, t[2] = a[2] + n[2] * r, t[3] = a[3] + n[3] * r, t[4] = a[4] + n[4] * r, t[5] = a[5] + n[5] * r, t[6] = a[6] + n[6] * r, t[7] = a[7] + n[7] * r, t[8] = a[8] + n[8] * r, t[9] = a[9] + n[9] * r, t[10] = a[10] + n[10] * r, t[11] = a[11] + n[11] * r, t[12] = a[12] + n[12] * r, t[13] = a[13] + n[13] * r, t[14] = a[14] + n[14] * r, t[15] = a[15] + n[15] * r, t;
}
function B(t, a) {
  return t[0] === a[0] && t[1] === a[1] && t[2] === a[2] && t[3] === a[3] && t[4] === a[4] && t[5] === a[5] && t[6] === a[6] && t[7] === a[7] && t[8] === a[8] && t[9] === a[9] && t[10] === a[10] && t[11] === a[11] && t[12] === a[12] && t[13] === a[13] && t[14] === a[14] && t[15] === a[15];
}
function C(t, n) {
  if (t === n) return !0;
  const r = t[0],
    o = t[1],
    s = t[2],
    e = t[3],
    h = t[4],
    u = t[5],
    M = t[6],
    c = t[7],
    i = t[8],
    f = t[9],
    b = t[10],
    l = t[11],
    m = t[12],
    x = t[13],
    q = t[14],
    d = t[15],
    p = n[0],
    g = n[1],
    y = n[2],
    R = n[3],
    v = n[4],
    S = n[5],
    T = n[6],
    I = n[7],
    O = n[8],
    j = n[9],
    P = n[10],
    D = n[11],
    _ = n[12],
    A = n[13],
    w = n[14],
    F = n[15],
    Q = a();
  return Math.abs(r - p) <= Q * Math.max(1, Math.abs(r), Math.abs(p)) && Math.abs(o - g) <= Q * Math.max(1, Math.abs(o), Math.abs(g)) && Math.abs(s - y) <= Q * Math.max(1, Math.abs(s), Math.abs(y)) && Math.abs(e - R) <= Q * Math.max(1, Math.abs(e), Math.abs(R)) && Math.abs(h - v) <= Q * Math.max(1, Math.abs(h), Math.abs(v)) && Math.abs(u - S) <= Q * Math.max(1, Math.abs(u), Math.abs(S)) && Math.abs(M - T) <= Q * Math.max(1, Math.abs(M), Math.abs(T)) && Math.abs(c - I) <= Q * Math.max(1, Math.abs(c), Math.abs(I)) && Math.abs(i - O) <= Q * Math.max(1, Math.abs(i), Math.abs(O)) && Math.abs(f - j) <= Q * Math.max(1, Math.abs(f), Math.abs(j)) && Math.abs(b - P) <= Q * Math.max(1, Math.abs(b), Math.abs(P)) && Math.abs(l - D) <= Q * Math.max(1, Math.abs(l), Math.abs(D)) && Math.abs(m - _) <= Q * Math.max(1, Math.abs(m), Math.abs(_)) && Math.abs(x - A) <= Q * Math.max(1, Math.abs(x), Math.abs(A)) && Math.abs(q - w) <= Q * Math.max(1, Math.abs(q), Math.abs(w)) && Math.abs(d - F) <= Q * Math.max(1, Math.abs(d), Math.abs(F));
}
function G(t) {
  const n = a(),
    r = t[0],
    o = t[1],
    s = t[2],
    e = t[4],
    h = t[5],
    u = t[6],
    M = t[8],
    c = t[9],
    i = t[10];
  return Math.abs(1 - (r * r + e * e + M * M)) <= n && Math.abs(1 - (o * o + h * h + c * c)) <= n && Math.abs(1 - (s * s + u * u + i * i)) <= n;
}
function H(t) {
  return 1 === t[0] && 0 === t[1] && 0 === t[2] && 0 === t[4] && 1 === t[5] && 0 === t[6] && 0 === t[8] && 0 === t[9] && 1 === t[10];
}
const J = c,
  K = E,
  L = Object.freeze(Object.defineProperty({
    __proto__: null,
    add: z,
    adjoint: u,
    copy: n,
    determinant: M,
    equals: C,
    exactEquals: B,
    frob: k,
    fromQuat: _,
    fromQuat2: S,
    fromRotation: p,
    fromRotationTranslation: v,
    fromRotationTranslationScale: P,
    fromRotationTranslationScaleOrigin: D,
    fromScaling: d,
    fromTranslation: q,
    fromXRotation: g,
    fromYRotation: y,
    fromZRotation: R,
    frustum: A,
    getRotation: j,
    getScaling: O,
    getTranslation: I,
    hasIdentityRotation: H,
    identity: o,
    invert: h,
    invertOrIdentity: e,
    isOrthoNormal: G,
    lookAt: X,
    mul: J,
    multiply: c,
    multiplyScalar: N,
    multiplyScalarAndAdd: V,
    ortho: Q,
    perspective: w,
    perspectiveFromFieldOfView: F,
    rotate: b,
    rotateX: l,
    rotateY: m,
    rotateZ: x,
    scale: f,
    set: r,
    str: Z,
    sub: K,
    subtract: E,
    targetTo: Y,
    translate: i,
    transpose: s
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { z as add, u as adjoint, n as copy, M as determinant, C as equals, B as exactEquals, k as frob, _ as fromQuat, S as fromQuat2, p as fromRotation, v as fromRotationTranslation, P as fromRotationTranslationScale, D as fromRotationTranslationScaleOrigin, d as fromScaling, q as fromTranslation, g as fromXRotation, y as fromYRotation, R as fromZRotation, A as frustum, j as getRotation, O as getScaling, I as getTranslation, H as hasIdentityRotation, o as identity, h as invert, e as invertOrIdentity, G as isOrthoNormal, X as lookAt, L as m, J as mul, c as multiply, N as multiplyScalar, V as multiplyScalarAndAdd, Q as ortho, w as perspective, F as perspectiveFromFieldOfView, b as rotate, l as rotateX, m as rotateY, x as rotateZ, f as scale, r as set, Z as str, K as sub, E as subtract, Y as targetTo, i as translate, s as transpose };